<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CCol xl="6">
                <CButton color="primary" class="btn-block col-xl-2" to="/users"><v-icon name="angle-left" class="v-mid-back"/> Back</CButton>
            </CCol>
        </CCardHeader>
        <CCardBody>
            <CCol xl="12" style="text-align: center;">
              <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
              />
            </CCol>
            <CForm v-if="!isLoading">
              <CInput
                label="NRP"
                type="text"
                vertical
                v-model="form.nrp"
                readonly
              />
              <CInput
                label="Full Name"
                type="text"
                vertical
                v-model="form.name"
                readonly
              />
              <CInput
                label="Email"
                type="email"
                vertical
                v-model="form.email"
                readonly
              />
              <CInput
                label="Phone Number"
                type="text"
                vertical
                v-model="form.phone"
                readonly
              />
              <CTextarea
                label="Address"
                vertical
                rows="4"
                v-model="form.address"
                readonly
              />
              <div class="form-group" role="group">
                <label>Privilege</label>
                <v-select 
                    :reduce="roles => roles.value"
                    v-model="form.role_id"
                    :options="roles"
                    disabled
                />
                <small class="form-text text-muted w-100"></small>
              </div>
              <div class="form-group" role="group">
                <label>Division</label>
                <v-select 
                    :reduce="divisions => divisions.value"
                    v-model="form.division_id"
                    :options="divisions"
                    disabled
                />
                <small class="form-text text-muted w-100"></small>
              </div>
              <div class="form-group" role="group">
                <label>Air Base</label>
                <multiselect
                  v-model="value_airports"
                  :options="airports"
                  track-by="value"
                  label="label"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  disabled>
                </multiselect>
                <small class="form-text text-muted w-100"></small>
              </div>
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
            </CForm>
        </CCardBody>
      </CCard>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DetailUsers',
  components: {
  },
  props: {
    valueairports: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      isLoading: false,
      roles: [],
      divisions: [],
      airports: [],
      value_airports: this.valueairports,
      form: {
        uuid: '',
        nrp: '',
        name: '',
        profile_picture: null,
        address: '',
        phone: '',
        email: '',
        password: '',
        role_id: '',
        division_id: '',
        airport_id: []
      },
      formData: null,
      url: null,
    }
  },
  async created() {
    this.isLoading = true
    await this.getRole()
    await this.getDivision()
    await this.getAirport()
    if(this.$route.params.id) {
        await this.showData()
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('users', ['showUSR']),
    ...mapActions('role', ['indexRL']),
    ...mapActions('division', ['indexDVS']),
    ...mapActions('airport', ['indexAPT']),
    async showData(){
      await this.showUSR(this.$route.params.id).then((response) => {
        this.form = response
        this.url = response.profile_picture
        this.form.profile_picture = null
        this.filterAirport(response.airport_rights)
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
      })
    },
    async getRole(){
        await this.indexRL().then((response) => {
            response.forEach((item, index) => {
                this.roles[index] = { value: item.id, label: item.name }
            })
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
        })
    },
    async getDivision(){
        await this.indexDVS().then((response) => {
            response.forEach((item, index) => {
                this.divisions[index] = { value: item.id, label: item.name }
            })
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
        })
    },
    async getAirport(){
        await this.indexAPT().then((response) => {
            response.forEach((item, index) => {
              if(item.is_active) {
                this.airports[index] = { value: item.id, label: item.name }
              }
            })
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
        })
    },
    filterAirport(val) {
      this.value_airports = val.map(function (obj) {
        return { value: obj.id, label: obj.name }
      })
    },
  }
}
</script>
<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #preview img {
    max-width: 40%;
    max-height: 40%;
  }
</style>